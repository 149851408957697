import Barcode from 'react-barcode';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { addAlert } from 'components/Alerts/actions';
import { hideBusy, showBusy } from 'components/Busy/actions';
import Loading from 'components/Loading';
import { go as navigate } from 'components/Navigate/actions';
import BasePage from 'components/Page';
import Retry from 'components/Retry';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import { getIdByFacilityCode, getIdByNetParkCode } from 'entities/Facility/util';
import {
  cancel as cancelReservation,
  fetch as fetchReservation,
  resendReceipt,
} from 'entities/Reservation/actions';
import ReservationDetails from 'scenes/Reservations/components/ReservationDetails';
import { storeOnContext } from 'util/Context/actions';
import { clone } from 'util/index';
import ConfirmCancel from '../../components/ConfirmCancel';
import './styles.scss';

/* View single reservation. */
class ViewReservation extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for reservation management
    this.state = {
      ...this.state,
      fetchError: false,
      loading: false,
      reservation: undefined,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // if the parking provider is down, we can't be here
    if (!this.props.status?.parking) {
      // go home
      return this.props.parkingProviderDisabled();
    }

    // load reservation
    if (!this.state.loading) {
      this.loadReservation(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we don't have a reservation, load it
    if (!this.state.loading && this.state.reservation === undefined) {
      this.loadReservation(this.props);
    }
  }

  loadReservation(props) {
    if (
      props.match &&
      props.match.params &&
      props.match.params.reservationId &&
      props.match.params.facilityId &&
      props.match.params.customerId
    ) {
      // if we already have the desired reservation, do nothing
      if (
        this.state.reservation &&
        String(this.state.reservation.id) === String(props.match.params.reservationId)
      ) {
        return;
      }

      // loading...
      this.setState({
        fetchError: false,
        loading: true,
        reservation: null,
      });

      // find this reservation
      props
        .fetchReservation(
          props.match.params.facilityId,
          props.match.params.customerId,
          props.match.params.reservationId,
        )
        .then((reservation) => {
          // set it on the state
          this.setState({
            fetchError: false,
            reservation: reservation,
          });
        })
        .catch((e) => {
          // let's see if we can handle this error intelligently
          if (e.code && e.code === 6000) {
            // reservation not found
            this.setState({
              fetchError: false,
              reservation: null,
            });
          } else {
            // log it
            console.error('Error fetching reservation:', e);

            // flag it so we can show an error
            this.setState({ fetchError: true });
          }
        })
        .finally(() => {
          // no longer loading
          this.setState({
            loading: false,
          });
        });
    } else if (this.state.reservation) {
      // clear the reservation
      this.setState({
        fetchError: false,
        loading: false,
        reservation: undefined,
      });
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // error?
    if (this.state.fetchError) {
      return (
        <div>
          <div className="container">
            <div className="row">
              <div className="col png-page-header">Reservation</div>
            </div>
          </div>
          <Retry onRefresh={() => this.loadReservation(this.props)} />
        </div>
      );
    }

    // still loading?
    else if (!this.state.reservation && this.state.loading) {
      return (
        <div className="container">
          <div className="row">
            <div className="col png-page-header">Reservation</div>
          </div>
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    // render reservation
    else {
      // new reservation?
      const newReservation = new URLSearchParams(document.location.search).get('new');

      // render
      return (
        <div className="container-fluid png-reservation-view">
          <div className="row">
            <div className="col png-page-header d-print-none">Reservation</div>
          </div>
          {this.state.reservation === null && (
            <>
              <div className="row">
                <div className="col">
                  <h2>Reservation Not Found</h2>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    We looked everywhere, and we can't find the requested reservation. Sorry about
                    that!
                  </p>
                  <p>
                    Why not{' '}
                    <Link to="/reservations/new" title="New Reservation">
                      create a new one
                    </Link>
                    ?
                  </p>
                </div>
              </div>
            </>
          )}
          {this.state.reservation && (
            <>
              {this.state.reservation.id && (
                <div className="row">
                  <div className="col text-center">
                    <Barcode value={String(this.state.reservation.id)} height={50} width={2} />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  {/* details */}
                  {this.state.reservation && (
                    <div>
                      <ReservationDetails reservation={this.state.reservation} />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {!newReservation && (
                  <div
                    className={`col-${
                      !this.state.reservation.status || this.state.reservation.status !== 'pending'
                        ? '12'
                        : '4'
                    } text-${
                      !this.state.reservation.status || this.state.reservation.status !== 'pending'
                        ? 'center'
                        : 'left'
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        // get to the list
                        this.props.history.push('/reservations');
                      }}
                      className="btn btn-primary btn-lg d-print-none png-reservation-view-back"
                    >
                      Back
                    </button>
                  </div>
                )}
                {this.state.reservation.status && this.state.reservation.status === 'pending' && (
                  <>
                    <div className={`col-${newReservation ? 6 : 4} text-center`}>
                      <button
                        type="button"
                        onClick={() => {
                          // launch the edit flow
                          const facilityId = getIdByFacilityCode(
                            this.props.facilities,
                            this.state.reservation.facilityCode,
                          );
                          this.props.history.push(
                            `/reservations/edit/${facilityId}/${this.state.reservation.customer.email}/${this.state.reservation.id}`,
                          );
                        }}
                        className="btn btn-primary btn-lg d-print-none png-reservation-view-edit"
                      >
                        Edit
                      </button>
                    </div>
                    <div
                      className={`col-${newReservation ? 6 : 4} text-${
                        newReservation ? 'center' : 'right'
                      }`}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <ConfirmCancel
                                  onClose={onClose}
                                  onConfirm={() => {
                                    // fire the cancel
                                    this.props
                                      .cancelReservation(
                                        getIdByFacilityCode(
                                          this.props.facilities,
                                          this.state.reservation.facilityCode,
                                        ),
                                        this.state.reservation.customer.email,
                                        this.state.reservation,
                                      )
                                      .then((reservation) => {
                                        // in case the reservation involved points, we refresh the customer
                                        if (
                                          reservation &&
                                          reservation.fppRedeemed &&
                                          this.props.customer &&
                                          this.props.facilities
                                        ) {
                                          this.props.refreshCustomer(
                                            this.props.customer,
                                            this.props.facilities,
                                          );
                                        }

                                        // if it worked, get back to the list
                                        if (reservation) {
                                          this.props.history.push('/reservations');
                                        } else {
                                          // it didn't work; stay here
                                        }
                                      });
                                  }}
                                />
                              );
                            },
                          });
                        }}
                        className="btn btn-primary btn-lg d-print-none png-reservation-view-cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
              {this.state.reservation.status && this.state.reservation.status === 'redeemed' && (
                <div className="row">
                  <div className="col text-center">
                    <button
                      type="button"
                      onClick={() => {
                        // fire the receipt resend
                        this.props.resendReceipt(
                          getIdByFacilityCode(
                            this.props.facilities,
                            this.state.reservation.facilityCode,
                          ),
                          this.state.reservation.customer.email,
                          this.state.reservation,
                        );
                      }}
                      className="btn btn-primary btn-lg d-print-none png-reservation-view-cancel"
                    >
                      Resend Receipt
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // status
    status: state.context.status,

    // the logged in customer, if there is one
    customer: state.context.customer,

    // facilities
    facilities: state.context.facilities,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // parking provider disabled
  parkingProviderDisabled: () => {
    // show an alert
    dispatch(
      addAlert(
        'warning',
        'Our online reservation system is undergoing maintenance. Please call ' +
          'one of our lots directly to make a reservation.',
        8000,
      ),
    );

    // go home
    dispatch(navigate('home'));
  },

  // find a reservation
  fetchReservation: (facilityId, email, reservationId) => {
    return dispatch(fetchReservation(facilityId, email, reservationId));
  },

  // requests a receipt resend
  resendReceipt: (facilityId, email, reservation) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    return dispatch(resendReceipt(facilityId, email, reservation.id))
      .then(() => {
        // toss an alert
        dispatch(
          addAlert(
            'success',
            'The receipt for this reservation has been sent to the email address ' +
              'under which the reservation was booked. If you need it sent elsewhere, ' +
              'please contact the facility directly for assistance.',
            8000,
          ),
        );
      })
      .catch((e) => {
        // log it
        console.error('Error resending reservation receipt', e);

        // toss an alert
        dispatch(
          addAlert(
            'error',
            'We ran into a problem finding the receipt for this reservation. Sorry about that! Please ' +
              " contact the facility directly, and they'll be able to help.",
            8000,
          ),
        );
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // cancel a reservation
  cancelReservation: (facilityId, email, reservation) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    return dispatch(cancelReservation(facilityId, email, reservation.id))
      .then(() => {
        // re-fetch it
        return dispatch(fetchReservation(facilityId, email, reservation.id))
          .then((reservation) => {
            // tell the customer what we did
            dispatch(
              addAlert(
                'success',
                'Your reservation has been canceled.' +
                  (reservation.prepaid > 0 ? ' Prepaid charges have been refunded.' : ''),
                6000,
              ),
            );

            // send it back
            return reservation;
          })
          .catch((e) => {
            // log it
            console.error('Error finding reservation after cancelation', e);

            // cheat; note that we need a new object here
            reservation = clone(reservation);
            reservation.status = 'canceled';

            return reservation;
          });
      })
      .catch((e) => {
        var alert =
          'We ran into a problem canceling your reservation. Sorry about that! Please ' +
          ' wait a minute and try again. If the problem persists, please contact support.';
        var timeout = 8000;
        var ret = null;

        // let's see if we can handle this error intelligently
        if (e.code) {
          switch (e.code) {
            case 6017:
              alert =
                `It's too close to the check in time, so this reservation cannot be canceled online. ` +
                'Sorry about that. Please call the facility for assistance.';
              timeout = 4000;
              break;
            case 6001:
              alert =
                'This reservation has already been redeemed and therefore cannot be canceled.';
              timeout = 4000;
              break;
            case 6002:
              alert =
                "This reservation has already been canceled once, and that's good enough. It's dead, Jim.";
              timeout = 4000;
              break;
            case 6008:
              alert =
                "Your reservation was canceled, but we couldn't determine if we needed to refund any money. " +
                "If not, you're good to go. If so, please contact us and let us know, and we'll get things " +
                'squared away for you. Sorry about that!';
              timeout = 10000;
              ret = reservation;
              break;
            case 6009:
              alert =
                'This is embarassing. Your reservation was canceled, but we failed to refund your money. ' +
                'The sytem has already contacted our customer support staff and explained the situation. ' +
                "Give them 5 days to get things worked out, and if you don't see a refund by then, please " +
                'contact us. Sorry about that!';
              timeout = 12000;
              ret = reservation;
              break;
            default:
              break;
          }
        }

        // log it
        console.error('Error canceling reservation', e);

        // toss the alert
        dispatch(addAlert('error', alert, timeout));

        return ret;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // refreshes the logged in customer
  refreshCustomer: (customer, facilities) => {
    // if not in the sandbox, refresh the customer
    if (customer && facilities) {
      const facilityId = getIdByNetParkCode(facilities, customer.locationCode);
      if (facilityId > 0) {
        dispatch(fetchCustomer(facilityId, customer.email, customer.alternateId))
          .then((customer) => {
            // refresh it on the context
            dispatch(storeOnContext('customer', customer));
          })
          .catch((e) => {
            // eat it
            console.error('Error refreshing customer', e);
          });
      }
    }
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewReservation));
