import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, clearSubmitErrors, Form, formValueSelector, reduxForm } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import MessageBox from 'components/MessageBox/index';
import './styles.scss';

/* Packages form. */
class PackagesForm extends BaseForm {
  constructor(props) {
    // parent
    super(props);

    // if there is only one package, select it
    if (this.props.packages && this.props.packages.length === 1) {
      this.props.selectPackage(this.props.packages[0]);
    }

    // no form fields, so we're valid by default
    this.state = {
      ...this.state,
      htmlValid: true,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // do we have a selected package?
    if (this.props.packages && this.props.packages.length > 0 && this.props.selectedPackage) {
      // re-select it to update values
      const selectedPackage = this.props.packages.filter(
        (p) => p.rateName === this.props.selectedPackage,
      );
      if (selectedPackage.length === 1) {
        this.props.selectPackage(selectedPackage[0]);
      } else {
        // our previously selected package is no longer available
        this.props.selectPackage(null);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // did a customer login?
    if (!prevProps.customer && this.props.customer) {
      // reset selected package
      this.props.selectPackage(null);

      // reset points
      this.props.dispatch(change(this.props.form, 'fppRedeemed', 0));

      // reload
      this.props.onCustomerChange({
        ...this.props.currentValues,
        fppRedeemed: 0, // reset points
      });
    }
  }

  renderFPPSelector() {
    // values we'll need
    const locationCode = this.props.locationCode;
    const customer = this.props.customer;
    const selectedPackage = this.props.selectedPackage;
    const packages = this.props.packages;
    const formValues = this.props.currentValues;
    var fppRedeemed = this.props.fppRedeemed;

    // points are only applicable at the customer's primary location
    if (locationCode && customer && locationCode === customer.locationCode) {
      // make sure the user has enough points for at least one day on one package
      var enough = false;
      for (var i = 0; i < packages.length; i++) {
        if (customer.fpp >= packages[i].fppPerDay) {
          enough = true;
          break;
        }
      }

      // figure out points required by the selected package
      var pointsRequired = 0;
      if (selectedPackage) {
        for (var j = 0; j < packages.length; j++) {
          if (selectedPackage === packages[j].rateName) {
            pointsRequired = packages[j].fppPerDay;
            break;
          }
        }
      }

      // figure out how many days the user is parking; we can get
      // the values we need off of any of the packages
      var parkedDays = 1 * packages[0].days + 7 * packages[0].weeks;

      // if we don't have a round number of points redeemed (due to a
      // package change, for example), we need to fix that
      var changed = false;
      if (
        fppRedeemed > 0 &&
        (pointsRequired === 0 ||
          Math.floor(fppRedeemed / pointsRequired) !== fppRedeemed / pointsRequired)
      ) {
        // change it
        fppRedeemed =
          pointsRequired > 0 ? Math.floor(fppRedeemed / pointsRequired) * pointsRequired : 0;
        changed = true;

        // fringe case; going from a package is eligible for free days
        // to one that is not means we have to requote
        if (pointsRequired === 0) {
          this.props.onPointChange({
            ...formValues,
            fppRedeemed: fppRedeemed,
          });
        }
      }

      // how many days have we redeemed?
      const freeDays =
        fppRedeemed > 0 && pointsRequired > 0 ? Math.floor(fppRedeemed / pointsRequired) : 0;

      // make sure we don't have more free days than parked days
      if (freeDays > parkedDays) {
        // change it
        fppRedeemed = parkedDays * pointsRequired;
        changed = true;
      }

      // did we change it?
      if (changed) {
        // update the form
        this.props.dispatch(change(this.props.form, 'fppRedeemed', fppRedeemed));

        // the change will cause us to re-enter this method
        return;
      }

      // if we have enough, show the FPP content
      if (enough) {
        return (
          <div className="container-fluid png-reservation-book-package-fpp">
            <div className="row">
              <div className="col">
                <h4>Free Days!</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h6>
                  You are a savvy parker, and savvy parkers deserve free days.{' '}
                  {selectedPackage && pointsRequired > 0 && pointsRequired <= customer.fpp && (
                    <span>
                      The selected package requires{' '}
                      <strong>
                        {pointsRequired} point
                        {pointsRequired !== 1 ? 's' : ''}
                      </strong>{' '}
                      per free day.{' '}
                      {(parkedDays === 1 || pointsRequired * 2 > customer.fpp) && (
                        <span>Would you like to redeem a day?</span>
                      )}
                      {parkedDays > 1 && pointsRequired * 2 <= customer.fpp && (
                        <span>How many days would you like to redeem?</span>
                      )}
                    </span>
                  )}
                  {selectedPackage && pointsRequired > 0 && pointsRequired > customer.fpp && (
                    <span>
                      Unfortunately, the selected package requires{' '}
                      <strong>
                        {pointsRequired} point
                        {pointsRequired !== 1 ? 's' : ''}
                      </strong>{' '}
                      per free day, which means you don't quite have enough. How about trying
                      another package?
                    </span>
                  )}
                  {!selectedPackage && <span>Select a rate above to get started.</span>}
                  {selectedPackage && pointsRequired === 0 && (
                    <span>
                      {/* this is really a data problem, but we need to handle it */}
                      Unfortunately, this package is not eligible for free days.
                    </span>
                  )}
                </h6>
              </div>
            </div>
            {pointsRequired > 0 && pointsRequired <= customer.fpp && (
              <div>
                <div className="row">
                  <div className="col text-center">
                    <button
                      type="button"
                      onClick={() => {
                        // calculate the new value
                        fppRedeemed -= pointsRequired;

                        // requote with the new value...
                        this.props
                          .onPointChange({
                            ...formValues,
                            fppRedeemed: fppRedeemed,
                          })
                          .then((packages) => {
                            // ... and then update the form
                            this.props.dispatch(
                              change(this.props.form, 'fppRedeemed', fppRedeemed),
                            );

                            // had the user already selected a package?
                            if (packages && packages.length > 0 && this.props.selectedPackage) {
                              // re-select it to update values
                              const selectedPackage = packages.filter(
                                (p) => p.rateName === this.props.selectedPackage,
                              );
                              if (selectedPackage.length === 1) {
                                this.props.selectPackage(selectedPackage[0]);
                              }
                            }
                          });
                      }}
                      className="btn btn-primary btn-lg png-reservation-book-package-fpp-change"
                      disabled={fppRedeemed === 0}
                    >
                      <FontAwesomeIcon icon="minus" />
                    </button>
                    <span className="align-middle png-reservation-book-package-fpp-days">
                      {freeDays}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        // calculate the new value
                        fppRedeemed += pointsRequired;

                        // requote with the new value...
                        this.props
                          .onPointChange({
                            ...formValues,
                            fppRedeemed: fppRedeemed,
                          })
                          .then((packages) => {
                            // ... and then update the form
                            this.props.dispatch(
                              change(this.props.form, 'fppRedeemed', fppRedeemed),
                            );

                            // had the user already selected a package?
                            if (packages && packages.length > 0 && this.props.selectedPackage) {
                              // re-select it to update values
                              const selectedPackage = packages.filter(
                                (p) => p.rateName === this.props.selectedPackage,
                              );
                              if (selectedPackage.length === 1) {
                                this.props.selectPackage(selectedPackage[0]);
                              }
                            }
                          });
                      }}
                      className="btn btn-primary btn-lg png-reservation-book-package-fpp-change"
                      disabled={
                        freeDays >= parkedDays || customer.fpp < fppRedeemed + pointsRequired
                      }
                    >
                      <FontAwesomeIcon icon="plus" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    {`${fppRedeemed} Point` + (fppRedeemed !== 1 ? 's' : '')}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }
    }

    return null;
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // first we need to group them
    var groups = [];
    var packages = this.props.packages;
    for (var i = 0; i < packages.length; i++) {
      // make sure each package has a group, even if it's empty
      if (!packages[i].groupName) {
        packages[i].groupName = '';
      }

      // get the group for this package
      var group = null;
      for (var j = 0; j < groups.length; j++) {
        if (groups[j].name === packages[i].groupName) {
          group = groups[j];
          break;
        }
      }

      // if we didn't find one, create it and add it to the list
      if (!group) {
        group = { name: packages[i].groupName, packages: [] };
        groups.push(group);
      }

      // add it to the group
      group.packages.push(packages[i]);
    }

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* packages */}
        <div className="container-fluid png-reservation-book-package-groups">
          {groups.map((group) => (
            <div key={group.name} className="row">
              <div className="col">
                <div className="container-fluid png-reservation-book-package-group png-background">
                  {groups.length > 1 && (
                    <div className="row">
                      <div className="col png-reservation-book-package-group-name">
                        {group.name}
                      </div>
                    </div>
                  )}
                  {group.packages.map((p) => (
                    <div key={group.name + '-' + p.rateName} className="row">
                      <div className="col">
                        <div
                          className={
                            `container-fluid png-reservation-book-package` +
                            (this.props.selectedPackage && this.props.selectedPackage === p.rateName
                              ? '-selected'
                              : '')
                          }
                          onClick={() => {
                            this.props.selectPackage(p);
                          }}
                        >
                          <div className="row">
                            <div className="col png-reservation-book-package-package-name">
                              <input
                                type="radio"
                                name="selectedPackage"
                                value={p.displayName}
                                checked={
                                  this.props.selectedPackage &&
                                  this.props.selectedPackage === p.rateName
                                }
                              />
                              &nbsp;<label htmlFor="selectedPackage">{p.displayName}</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1"> </div>
                            <div className="col">Parking</div>
                            <div className="col-4 text-right text-nowrap">
                              ${p.parking.toFixed(2)}
                            </div>
                          </div>
                          {this.props.services && this.props.services.length > 0 && (
                            <div className="row">
                              <div className="col-1">
                                <FontAwesomeIcon icon="plus" />
                              </div>
                              <div className="col">Services</div>
                              <div className="col-4 text-right text-nowrap">
                                ${p.services.toFixed(2)}
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-1">
                              <FontAwesomeIcon icon="plus" />
                            </div>
                            <div className="col">Taxes & Fees</div>
                            <div className="col-4 text-right text-nowrap">
                              ${(p.taxes + p.options).toFixed(2)}
                            </div>
                          </div>
                          {!(typeof p.promo === 'undefined') && p.promo > 0 && (
                            <div className="row">
                              <div className="col-1">
                                <FontAwesomeIcon icon="minus" />
                              </div>
                              <div className="col">Savings</div>
                              <div className="col-4 text-right text-nowrap">
                                ${p.promo.toFixed(2)}
                              </div>
                            </div>
                          )}
                          <div className="row png-reservation-book-package-total">
                            <div className="col-1">
                              <FontAwesomeIcon icon="equals" />
                            </div>
                            <div className="col">{p.prepay ? 'Due Now' : 'Due at Lot'}</div>
                            <div className="col-4 text-right text-nowrap">
                              ${p.total.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {}

        {/* Note on original payment, if applicable; we notify when:
         *   - changing prepaid => postpaid
         *   - changing postpaid => prepaid
         *   - changing the amount on prepaid
         */}
        {this.props.original &&
        ((this.props.original.prepaid && this.props.currentValues.payOnExit) || // prepaid => postpaid
          (this.props.original.payOnExit && this.props.currentValues.prepaid) || // postpaid => prepaid
          (this.props.original.prepaid &&
            this.props.original.prepaid &&
            this.props.original.prepaid !== this.props.currentValues.prepaid)) ? ( // prepaid => prepaid (different amount)
          <div className="row">
            <div className="col mt-2">
              <MessageBox flavor="info">
                {this.props.original.prepaid && this.props.currentValues.payOnExit ? (
                  // prepaid => postpaid
                  <>
                    You will be refunded $&#8204;{this.props.original.prepaid.toFixed(2)} for the
                    original reservation, which will be canceled. A new reservation will be created,
                    and you will be charged $&#8204;{this.props.currentValues.payOnExit.toFixed(2)}{' '}
                    when checking out of the lot.
                  </>
                ) : this.props.original.payOnExit && this.props.currentValues.prepaid ? (
                  // postpaid => prepaid
                  <>
                    Your original reservation will be canceled, and you will be charged $&#8204;
                    {this.props.currentValues.prepaid.toFixed(2)} for the new reservation.
                  </>
                ) : (
                  // prepaid => prepaid (different amount)
                  <>
                    Due to the amount change, your original reservation will be canceled, and you
                    will be refunded $&#8204;
                    {this.props.original.prepaid.toFixed(2)}. A new reservation will be created, and
                    you will be charged $&#8204;{this.props.currentValues.prepaid.toFixed(2)}.
                  </>
                )}
              </MessageBox>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* FP points */}
        {this.renderFPPSelector()}

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                !this.props.selectedPackage ||
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
PackagesForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(PackagesForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // the logged in customer, if there is one
  customer: state.context.customer,

  // selected package
  selectedPackage: formValueSelector(ownProps.form)(state, 'rateName'),

  // points redeemed
  fppRedeemed: formValueSelector(ownProps.form)(state, 'fppRedeemed')
    ? formValueSelector(ownProps.form)(state, 'fppRedeemed')
    : 0,

  // current form values
  currentValues: state.form.reservationForm.values,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  selectPackage: (p) => {
    if (p) {
      dispatch(change(ownProps.form, 'rateName', p.rateName));
      dispatch(change(ownProps.form, p.prepay ? 'prepaid' : 'payOnExit', p.total));
      dispatch(change(ownProps.form, p.prepay ? 'payOnExit' : 'prepaid', 0));
      dispatch(change(ownProps.form, 'payment.amount', p.prepay ? p.total : null));
    } else {
      dispatch(change(ownProps.form, 'rateName', null));
      dispatch(change(ownProps.form, 'prepaid', null));
      dispatch(change(ownProps.form, 'payOnExit', null));
      dispatch(change(ownProps.form, 'payment.amount', null));
    }
  },
});

// turn this into a container component
PackagesForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(PackagesForm));

// set default props
PackagesForm.defaultProps = {
  form: 'reservationForm',
};

export default PackagesForm;
