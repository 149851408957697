import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { hideBusy, showBusy } from 'components/Busy/actions';
import Loading from 'components/Loading';
import BasePage from 'components/Page';
import Retry from 'components/Retry';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import {
  getFacilityNameById,
  getIdByFacilityCode,
  getIdByNetParkCode,
} from 'entities/Facility/util';
import {
  cancel as cancelReservation,
  fetch as fetchReservation,
  fetchAll as fetchReservations,
} from 'entities/Reservation/actions';
import { storeOnContext } from 'util/Context/actions';
import { clone } from 'util/index';
import CancelAssistance from './components/CancelAssistance/index';
import ConfirmCancel from './components/ConfirmCancel';
import SearchForm from './components/SearchForm';
import './styles.scss';

/* View reservation. */
class ViewReservation extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for reservation management
    this.state = {
      ...this.state,
      fetchError: false,
      loading: false,
      reservations: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // load reservations
    if (!this.state.loading) {
      this.loadReservations(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we don't have reservations, load them
    if (!this.state.loading) {
      // load reservations
      this.loadReservations(this.props);
    }
  }

  loadReservations(props) {
    if (props.customer) {
      // if we already have some, don't reload
      if (this.state.reservations) {
        return;
      }

      // loading...
      this.setState({
        fetchError: false,
        loading: true,
        reservations: null,
      });

      // find all
      this.props
        .fetchReservations(props.customer.email, props.customer.lastName)
        .then((reservations) => {
          // set them on the state
          this.setState({
            fetchError: false,
            reservations: reservations,
          });
        })
        .catch((e) => {
          // log it
          console.error('Error fetching reservations:', e);

          // flag it so we can show an error
          this.setState({ fetchError: true });
        })
        .finally(() => {
          // no longer loading
          this.setState({
            loading: false,
          });
        });
    } else if (this.state.reservations) {
      // clear the reservations
      this.setState({
        fetchError: false,
        loading: false,
        reservations: null,
      });
    }
  }

  renderReservations(reservations, status) {
    // filter by status
    reservations = reservations.filter((r) => r.status === status);

    // sort by start time, descending
    reservations.sort(function (a, b) {
      return b.start - a.start;
    });

    // did we get any?
    if (reservations.length === 0) {
      return null;
    }

    // render
    return (
      <div>
        <div className="row">
          <div className="col">
            <h5>
              {(function () {
                switch (status) {
                  case 'pending':
                    return <span>Booked ({reservations.length})</span>;
                  case 'canceled':
                    return <span>Canceled ({reservations.length})</span>;
                  case 'redeemed':
                    return <span>Completed ({reservations.length})</span>;
                  default:
                    return null;
                }
              })()}
            </h5>
          </div>
        </div>
        {reservations.map((reservation) => (
          <div key={`${reservation.facilityCode}-${reservation.id}`} className="row">
            <div className="col">
              <div className="png-inline-link png-reservation-summary-location">
                <Link
                  to={
                    '/locations/' +
                    getIdByFacilityCode(this.props.facilities, reservation.facilityCode)
                  }
                >
                  {getFacilityNameById(
                    this.props.facilities,
                    getIdByFacilityCode(this.props.facilities, reservation.facilityCode),
                  )}
                </Link>
              </div>
              <div
                className={`container-fluid p-0 png-reservation-summary png-background png-reservation-summary-${reservation.status}`}
              >
                <div className="d-flex flex-row">
                  <div
                    className="png-reservation-summary-content d-flex flex-row"
                    onClick={() => {
                      const facilityId = getIdByFacilityCode(
                        this.props.facilities,
                        reservation.facilityCode,
                      );
                      this.props.history.push(
                        `/reservations/${facilityId}/${reservation.customer.email}/${reservation.id}`,
                      );
                    }}
                  >
                    <div className="container-fluid align-self-center">
                      <div className="row">
                        <div className="col-sm-2">
                          {' '}
                          <div className="container-fluid png-reservation-summary-content-item">
                            <div className="row png-reservation-summary-content-item-header">
                              <div className="col">ID</div>
                            </div>
                            <div className="row">
                              <div className="col">{reservation.id}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-5">
                          {reservation.start && (
                            <div className="container-fluid png-reservation-summary-content-item">
                              <div className="row png-reservation-summary-content-item-header">
                                <div className="col">Check In</div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  {moment.unix(reservation.start).format('ddd, M/D/YY [at] h:mm A')}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-5">
                          {reservation.end && (
                            <div className="container-fluid png-reservation-summary-content-item">
                              <div className="row png-reservation-summary-content-item-header">
                                <div className="col">Check Out</div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  {moment.unix(reservation.end).format('ddd, M/D/YY [at] h:mm A')}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {reservation.status === 'pending' && (
                    <div className="png-reservation-summary-actions text-center">
                      <div
                        className="png-reservation-summary-edit"
                        title="Edit"
                        onClick={() => {
                          // launch the edit flow
                          const facilityId = getIdByFacilityCode(
                            this.props.facilities,
                            reservation.facilityCode,
                          );
                          this.props.history.push(
                            `/reservations/edit/${facilityId}/${reservation.customer.email}/${reservation.id}`,
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="pencil-alt" />
                      </div>
                      <div
                        className="png-reservation-summary-cancel"
                        title="Cancel"
                        onClick={() => {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <ConfirmCancel
                                  onClose={onClose}
                                  onConfirm={() => {
                                    // fire the cancel
                                    this.props
                                      .cancelReservation(
                                        getIdByFacilityCode(
                                          this.props.facilities,
                                          reservation.facilityCode,
                                        ),
                                        reservation.customer.email,
                                        reservation,
                                      )
                                      .then((reservation) => {
                                        // if the reservation involved points, we refresh the customer
                                        if (
                                          reservation &&
                                          reservation.fppRedeemed &&
                                          this.props.customer &&
                                          this.props.facilities
                                        ) {
                                          this.props.refreshCustomer(
                                            this.props.customer,
                                            this.props.facilities,
                                          );
                                        }

                                        // replace this reservation in the list
                                        if (reservation) {
                                          // find this reservation
                                          var index = -1;
                                          for (var i = 0; i < this.state.reservations.length; i++) {
                                            if (reservation.id === this.state.reservations[i].id) {
                                              index = i;
                                              break;
                                            }
                                          }

                                          // replace it
                                          if (index >= 0) {
                                            this.setState({
                                              reservations: [
                                                ...this.state.reservations.slice(0, index),
                                                reservation,
                                                ...this.state.reservations.slice(index + 1),
                                              ],
                                            });

                                            // all done
                                            return;
                                          }
                                        } else {
                                          // something failed with the cancel; leave everything as is
                                          return;
                                        }
                                      });
                                  }}
                                />
                              );
                            },
                          });
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render reservations
    return (
      <div className="container-fluid png-reservation-search">
        <div className="row">
          <div className="col png-page-header">Reservations</div>
        </div>

        {/* customer logged in? */}
        {this.props.customer && (
          <>
            <div className="row">
              <div className="col">
                <h4>Your Reservations</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {this.state.reservations ? (
                  <>
                    <p>
                      We found the following reservation
                      {this.state.reservations.length === 1 ? '' : 's'} for you.
                    </p>
                    <p>
                      Note that we use your last name ({this.props.customer.lastName}) and email
                      address ({this.props.customer.email}) to store reservations and to apply
                      points. Therefore, if you have booked reservations under a different email
                      address or last name, they will not show up in this list. But don't worry, you
                      can still <a href="#search">search for them</a>!
                    </p>
                  </>
                ) : (
                  <>Fetching your reservations...</>
                )}
              </div>
            </div>
          </>
        )}

        {/* error? */}
        {this.state.fetchError && <Retry onRefresh={() => this.loadReservations(this.props)} />}

        {/* still loading? */}
        {this.state.loading && !this.state.fetchError && (
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        )}

        {/* cancellation assistance */}
        {!this.state.loading && <CancelAssistance />}

        {/* found reservations? */}
        {!this.state.loading && this.state.reservations && this.state.reservations.length !== 0 && (
          <div>
            {this.renderReservations(this.state.reservations, 'pending')}
            {this.renderReservations(this.state.reservations, 'redeemed')}
            {this.renderReservations(this.state.reservations, 'canceled')}
          </div>
        )}

        {/* search for a reservation */}
        {!this.state.loading && this.state.reservations && this.state.reservations.length === 0 && (
          <div>
            <div className="row">
              <div className="col">
                <h4>No Reservations</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>
                  We couldn't find any reservations under your account. If you are looking for a
                  specific reservation, feel free to search for it.
                </p>
              </div>
            </div>
          </div>
        )}
        {this.props.facilities && this.props.facilities.length > 0 && (
          <section id="search">
            <div className="row">
              <div className="col">
                <h4>Find Reservation</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>
                  Use this form to find reservations.
                  {this.props.customer && (
                    <span>
                      {' '}
                      You'll need to use the email address under which the reservation was booked,
                      even if that differs from your Frequent Parker email address.
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col" style={{ width: '100%', maxWidth: '500px' }}>
                <SearchForm
                  facilities={this.props.facilities}
                  onSubmit={(values) =>
                    this.props.searchReservation(values).then((reservation) => {
                      if (reservation) {
                        // view it
                        const facilityId = getIdByFacilityCode(
                          this.props.facilities,
                          reservation.facilityCode,
                        );
                        this.props.history.push(
                          `/reservations/${facilityId}/${reservation.customer.email}/${reservation.id}`,
                        );
                      }
                    })
                  }
                />
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // customer
    customer: state.context.customer,

    // facilities
    facilities: state.context.facilities,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // fetch all reservations
  fetchReservations: (email, lastName) => {
    return dispatch(fetchReservations(email, lastName));
  },

  // search for a reservation
  searchReservation: ({ facilityId, email, reservationId }) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    return dispatch(fetchReservation(facilityId, email, reservationId))
      .catch((e) => {
        // is this a "reservation not found" error?
        if (e.code && e.code === 6000) {
          // tell the user
          throw new SubmissionError({ _error: 'Reservation not found.' });
        }

        // log it
        console.error('Error searching for reservation', e);

        // tell the user
        throw new SubmissionError({
          _error: 'We ran into a problem! Please try again.',
        });
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // cancel a reservation
  cancelReservation: (facilityId, email, reservation) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    return dispatch(cancelReservation(facilityId, email, reservation.id))
      .then(() => {
        // re-fetch it
        return dispatch(fetchReservation(facilityId, email, reservation.id))
          .then((reservation) => {
            // tell the customer what we did
            dispatch(
              addAlert(
                'success',
                'Your reservation has been canceled.' +
                  (reservation.prepaid > 0 ? ' Prepaid charges have been refunded.' : ''),
                6000,
              ),
            );

            // send it back
            return reservation;
          })
          .catch((e) => {
            // log it
            console.error('Error finding reservation after cancelation', e);

            // cheat; note that we need a new object here
            reservation = clone(reservation);
            reservation.status = 'canceled';

            return reservation;
          });
      })
      .catch((e) => {
        var alert =
          'We ran into a problem canceling your reservation. Sorry about that! Please ' +
          ' wait a minute and try again. If the problem persists, please contact support.';
        var timeout = 8000;
        var ret = null;

        // let's see if we can handle this error intelligently
        if (e.code) {
          switch (e.code) {
            case 6017:
              alert =
                `It's too close to the check in time, so this reservation cannot be canceled online. ` +
                'Sorry about that. Please call the facility for assistance.';
              timeout = 4000;
              break;
            case 6001:
              alert =
                'This reservation has already been redeemed and therefore cannot be canceled.';
              timeout = 4000;
              ret = clone(reservation);
              ret.status = 'canceled';
              break;
            case 6002:
              alert =
                "This reservation has already been canceled once, and that's good enough. It's dead, Jim.";
              timeout = 4000;
              ret = clone(reservation);
              ret.status = 'canceled';
              break;
            case 6008:
              alert =
                "Your reservation was canceled, but we couldn't determine if we needed to refund any money. " +
                "If not, you're good to go. If so, please contact us and let us know, and we'll get things " +
                'squared away for you. Sorry about that!';
              timeout = 10000;
              ret = clone(reservation);
              ret.status = 'canceled';
              break;
            case 6009:
              alert =
                'This is embarassing. Your reservation was canceled, but we failed to refund your money. ' +
                'The sytem has already contacted our customer support staff and explained the situation. ' +
                "Give them 5 days to get things worked out, and if you don't see a refund by then, please " +
                'please contact us. Sorry about that!';
              timeout = 12000;
              ret = clone(reservation);
              ret.status = 'canceled';
              break;
            default:
              break;
          }
        }

        // log it
        console.error('Error canceling reservation', e);

        // toss the alert
        dispatch(addAlert('error', alert, timeout));

        return ret;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // refreshes the logged in customer
  refreshCustomer: (customer, facilities) => {
    // if not in the sandbox, refresh the customer
    if (customer && facilities) {
      const facilityId = getIdByNetParkCode(facilities, customer.locationCode);
      if (facilityId > 0) {
        dispatch(fetchCustomer(facilityId, customer.email, customer.alternateId))
          .then((customer) => {
            // refresh it on the context
            dispatch(storeOnContext('customer', customer));
          })
          .catch((e) => {
            // eat it
            console.error('Error refreshing customer', e);
          });
      }
    }
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewReservation));
